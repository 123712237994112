<template>
	<div>
		<a-spin :spinning="loading">
			<a-form v-if="!id" ref="form" name="form" :model="formState" @finish="onSearch">
			    <a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
			      <a-form-item class="ui-form__item" label="影片名称" name="filmName">
			        <a-input
			          placeholder="请输入"
			          v-model:value="formState.filmName"
			        ></a-input>
			      </a-form-item>
			
					<a-form-item class="ui-form__item" label="操作人" name="operateUser">
					  <a-input
					    placeholder="请输入操作人"
					    v-model:value="formState.operateUser"
					  ></a-input>
					</a-form-item>
					
					<a-form-item label="操作结果" name="status">
						<a-select v-model:value="formState.status" style="width: 180px;" placeholder="请选择销售渠道">
							<a-select-option :value="0">恢复失败</a-select-option>
							<a-select-option :value="1">恢复成功</a-select-option>
						</a-select>
					</a-form-item>
					
			        <a-form-item label="操作日期">
			            <a-range-picker v-model:value="time" :showTime="{ hideDisabledOptions: true, defaultValue:[moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }"></a-range-picker>
			        </a-form-item>
			    </a-row>
			
			    <a-row>
			        <a-col :span="18">
			        </a-col>
			        <a-col :span="6" style="text-align: right;">
			            <a-button type="primary" html-type="submit">搜索</a-button>
			            <a-button style="margin-left: 20px;" @click="reset">重置</a-button>
			        </a-col>
			    </a-row>
			</a-form>
			<a-table style="margin-top: 10px;" :columns="columns" :dataSource="list" rowKey="id" :pagination="pagination" :scroll="{ x: 1200 }">
				<template #bodyCell="{ column, record }">
					<template v-if="column.key === 'status'">
						{{ record.status === 0 ? '操作失败' : '操作成功' }}
					</template>
					<template v-if="column.key === 'showDateTime'">
						{{ transDateTime(record.showDateTime) }}
					</template>
					<template v-if="column.key === 'info'">
						<span v-if="record.operateParam">
							<div>
								恢复类型：{{ ['全量恢复', '按票数恢复', '按比例恢复'][record.operateParam.type - 1] }}
							</div>
							<div v-if="record.operateParam.type !== 1">
								{{ record.operateParam.num }} {{ record.operateParam.type === 2 ? '张' : '%' }}
							</div>
						</span>
						<span v-else>--</span>
					</template>
					<template v-if="column.key === 'time'">
						{{ transDateTime(record.createTime) }}
					</template>
				</template>
			</a-table>
		</a-spin>
	</div>
</template>

<script>
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getOperateLog } from '@/service/modules/order.js';
	export default {
		props: {
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				loading: false,
				time: [],
				formState: {
					cinemaId: 0
				},
				organizationList: [],
				cinemaAllList: [],
				searchData: {},
				list: [],
				columns: [{
					title: '影院信息',
					dataIndex: 'cinemaName'
				}, {
					title: '影片信息',
					dataIndex: 'filmName'
				}, {
					title: '放映时间',
					key: 'showDateTime'
				}, {
					title: '操作人',
					dataIndex: 'operateUser'
				}, {
					title: '操作结果',
					key: 'status'
				}, {
					title: '恢复信息',
					key: 'info'
				}, {
					title: '操作时间',
					key: 'time'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.selectedRowKeys = this.value;
			this.getOrganizationList();
			this.getData();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				if (this.time && this.time.length) {
					this.searchData.startCreateTime = this.time[0].unix();
					this.searchData.endCreateTime = this.time[1].unix();
				}
				this.getData();
			},
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getOperateLog({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						type: 3,
						primaryId: this.id ? this.id : undefined,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list.map(item => {
							if (item.operateParam) {
								item.operateParam = JSON.parse(item.operateParam);
							}
							return item;
						});
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					this.loading = false;
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						// this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = undefined;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onSelectChange(key) {
				this.selectedRowKeys = key;
				console.log(key)
				this.$emit('update:value', this.selectedRowKeys);
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
