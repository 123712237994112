<template>
    <div>
        <a-spin :spinning="loading">
            <a-form ref="form" name="form" :model="formState" @finish="onSearch">
                <a-row>
                    <a-form-item class="ui-form__item" name="organizationId" label="影院组织">
                        <a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="getAllCinemaList">
                            <!-- <a-select-option :value="0">全部</a-select-option> -->
                            <a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item class="ui-form__item" name="cinemaId" label="所属影院" :rules="[{ required: true,message:'必填项不允许为空' }]">
                        <a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" style="width: 180px;">
                            <!-- <a-select-option :value="0">全部</a-select-option>-->
                            <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
                        </a-select>
                    </a-form-item>

                   <a-form-item label="销售渠道" name="channel" class="ui-form__item">
                        <a-select v-model:value="formState.channel" style="width: 180px;" placeholder="请选择销售渠道">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option value="KIOSK">KIOSK</a-select-option>
                        </a-select>
                    </a-form-item>

                  <a-form-item class="ui-form__item" label="影片名称" name="filmName">
                    <a-input
                      placeholder="请输入"
                      v-model:value="formState.filmName"
                    ></a-input>
                  </a-form-item>

                    <a-form-item label="放映日期">
                        <a-range-picker v-model:value="time" :showTime="{ hideDisabledOptions: true, defaultValue:[moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }"></a-range-picker>
                    </a-form-item>

                </a-row>

                <a-row>
                    <a-col :span="18">
                        <a-button type="primary" v-permission="['report_restore_list_restore']" :disabled="selectedRowKeys.length === 0" @click="onBatchSync">批量恢复订单</a-button>
						<a-button style="margin-left: 20px;" type="primary" @click="onLook(false)">恢复记录查看</a-button>
                    </a-col>
                    <a-col :span="6" style="text-align: right;">
                        <a-button type="primary" html-type="submit">搜索</a-button>
                        <a-button style="margin-left: 20px;" @click="reset">重置</a-button>
                    </a-col>
                </a-row>
            </a-form>
            <a-table style="margin-top: 10px;" :columns="columns" :dataSource="list" rowKey="id" :pagination="pagination" :scroll="{x: 1600}"
                     :rowSelection="{ type: 'checkbox', selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
            >
                <template #bodyCell="{ column, record, index }">
					<template v-if="column.key === 'time'">
						{{ transDateTime(record.showDateTime) }}
					</template>
                    <template v-if="column.key === 'record'">
                        <a-button type="link" @click="onLook(record)">查看</a-button>
                    </template>
                    <template v-if="column.key === 'action'">
                        <a-button v-permission="['report_restore_list_restore']" type="link" @click="onSync(record)">恢复订单</a-button>
                    </template>
                </template>
            </a-table>

            <a-modal v-model:visible="showModal" destroyOnClose width="900px" title="恢复记录">
                <recordList :id="id"></recordList>
            </a-modal>

            <a-modal v-model:visible="resumeVisible" title="恢复订单至售票系统" width="750px" @ok="() => onResumeOk()">
                <a-form ref="resumeForm" name="resumeForm" :model="resumeModelRef" :labelCol="{ span: 4 }">
                    <a-form-item label="恢复方式" name="type" :rules="[{ required: true, message: '必选项不允许为空' }]">
                        <a-radio-group v-model:value="resumeModelRef.type">
                            <a-radio :value="1">全量恢复</a-radio>
                            <a-radio :value="2">按票数恢复</a-radio>
                            <a-radio :value="3">按比例恢复</a-radio>
                        </a-radio-group>
                    </a-form-item>

                    <a-form-item v-if="resumeModelRef.type === 2" label="票数" name="num" :rules="[{ required: true, message: '必填项不允许为空' }]"
                    >
                        <a-input-number v-model:value="resumeModelRef.num" :min="0" :precision="0" placeholder="请输入"></a-input-number> 张
                        <div style="margin-top: 10px;color: red;">
                            恢复订单数大于【已售票数】则实际恢复订单数等于已售票数。
                        </div>
                        <div style="color: red;">
                            例：当前已售票数10张，恢复订单【15张】则实际恢复订单10张至售票系统。
                        </div>
                    </a-form-item>

                    <a-form-item v-if="resumeModelRef.type === 3" label="比例" name="num" :rules="[{ required: true, message: '必填项不允许为空' }]">
                        <a-input-number v-model:value="resumeModelRef.num" :min="0" :precision="0" placeholder="请输入"></a-input-number> %
                        <div style="margin-top: 10px;color: red;">
                            恢复订单数大于【已售票数】则实际恢复订单数等于已售票数。
                        </div>
                        <div style="color: red;">
                            例：当前已售票数10张，恢复订单比例【35%】则实际恢复订单3张至售票系统。
                        </div>
                    </a-form-item>
                </a-form>
            </a-modal>
        </a-spin>
    </div>
</template>

<script>
	import {Icon} from '@/components/icon/icon.js';
	import recordList from './components/record.vue';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getSyncOrderList, syncOrder } from '@/service/modules/order.js';
	export default {
		components: { Icon, recordList },
		data() {
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				formState: {
					cinemaId: undefined,
					channel: 0,
				},
				time: [],
				searchData: {},
				selectedRowKeys: [],
				list: [],
				columns: [{
					title: '影院名称',
					dataIndex: 'cinemaName'
				}, {
					title: '影片',
					dataIndex: 'filmName'
				},{
					title: '场次时间',
					key: 'time',
					width: 200
				},{
					title: '影厅',
					dataIndex: 'hallName',
					width: 200
				},{
					title: '场区',
					dataIndex: 'areaName',
					width: 200
				},{
					title: '已售票数',
					dataIndex: 'ticketNum',
					width: 100
				}, {
				    title: '已恢复座位数',
					dataIndex: 'recoverNum',
				    width: 130
				},{
					title: '金额',
					dataIndex:'payment',
					width: 100
				}, {
					title: '恢复记录',
					key: 'record',
					width: 100
				},{
					title: '操作',
					key: 'action',
					align: 'center',
					fixed: 'right',
					width: 120
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				showModal: false,
				id: 0,

				resumeVisible: false,
				resumeModelRef: {
				    type: 1
				}
			}
		},
		created() {
			this.getOrganizationList();
			//this.onSearch();
		},
		methods: {
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.searchData.channel = this.searchData.channel ? this.searchData.channel : undefined;
				this.searchData.filmName = this.searchData.filmName ? this.searchData.filmName : undefined;
				if (this.time && this.time.length) {
					this.searchData.startTime = parseInt(this.time[0].valueOf() / 1000);
					this.searchData.endTime = parseInt(this.time[1].valueOf() / 1000);
				}
				this.getData();
			},
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.list = [];
				//this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getSyncOrderList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					this.loading = false;
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						// this.onSearch();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId) {
				this.cinemaAllList = [];
				this.formState.cinemaId = undefined;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
			onLook(item) {
				if (item) {
					this.id = item.id;
				} else {
					this.id = undefined;
				}
				this.showModal = true;
			},
			onBatchSync() {
			    this.id = 0;
			    this.resumeModelRef = {
				    type: 1
				}
			    this.resumeVisible = true;
			},
			onSync(item) {
			    this.id = item.id;
			    this.resumeModelRef = {
				    type: 1
				}
			    this.resumeVisible = true;
			},
			onResumeOk() {
				this.$confirm({
					title: '警告',
					// content: '该操作为【异步】操作，请不要在短时间内发起多次操作！操作结果请查看【恢复记录】',
					content: (<p>1.该操作为【异步】操作，请不要在短时间内发起多次操作！操作结果请查看【恢复记录】；<br />2.已释放的座位无法恢复；</p>),
					okText: '继续操作',
					cancelText: '暂不操作',
					onOk: ()=> {
						this.$confirm({
						    title: '提示',
						    content: '确定执行恢复订单至售票系统吗？',
						    onOk: async ()=> {
						        this.loading = true;
						        let ret = await syncOrder({
						            ids: this.id ? this.id : this.selectedRowKeys.join(','),
									type: this.resumeModelRef.type,
									num: this.resumeModelRef.type === 1 ? undefined : this.resumeModelRef.num
						        });
						        this.loading = false;
						        if (ret.code === 200) {
									this.resumeVisible = false;
						            this.$message.success('提交成功，请耐心等待！');
						            this.getData();
						        }
						    }
						})
					}
				})
			},
			onSelectChange(record, selected) {
				if (selected) {
					this.selectedRowKeys.push(record.id);
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
